import { api } from '.';
import { PLATFORM_COSTS_RESOURCE } from '@/services/keys';
import QueryParamsBuilder from '@/model/shared/QueryParamsBuilder';
import ResponseBuilder from '@/model/shared/ResponseBuilder';
import thirdPartyEntityParser from '@/entities/ThirdParty/ThirdPartyEntityParser';
import formatDateFiltersToUTC from '@/utils/formatDateFiltersToUTC';

export const getResources = () => ({
  [PLATFORM_COSTS_RESOURCE]: undefined,
});

/**
 *
 * @param {QueryParamsBuilder} params
 * @return {Promise<Object>}
 */
export async function getCostList(params = new QueryParamsBuilder(), config = { preventCancel: false }) {
  const partialUrl = api.createUrl(getResources());
  params.addInclude('platform');

  params.filters = {
    ...params.filters,
    ...formatDateFiltersToUTC(params.filters),
  };

  const { data } = await api.get(partialUrl, params.build(), config);
  return new ResponseBuilder(thirdPartyEntityParser.parse(data), data.meta).build();
}
