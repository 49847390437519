import Column from '@/model/shared/Column';

const today = new Date();

export default {
  headers: [
    new Column('PLATFORM NAME', 'platformName').setClass('text-gray-700 text-xs font-bold'),
    new Column('COST', 'amount').setClass('text-gray-700 text-xs').toRight(),
  ],
  defaultDate: {
    startDate: new Date(today.setMonth(today.getMonth() - 1)),
    endDate: new Date(),
  },
  breakdownOptions: {
    value: 'time',
    simple: true,
    items: [
      { name: 'By Day', value: 'day' },
      { name: 'By Month', value: 'month' },
    ],
  },
};
