var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("asterix-section", {
        attrs: { status: _vm.sectionStatus },
        scopedSlots: _vm._u([
          {
            key: "header-right",
            fn: function () {
              return [
                _c(
                  "sun-button",
                  {
                    staticClass:
                      "px-3 custom-p-1 text-xs bg-gray-700 hover:bg-gray-800 h-6 self-end",
                    attrs: { variant: "pill", color: "gray" },
                    on: { click: _vm.openModal },
                  },
                  [
                    _c("div", { staticClass: "flex items-center" }, [
                      _c(
                        "span",
                        { staticClass: "mr-2" },
                        [_c("upload-svg", { staticClass: "w-4" })],
                        1
                      ),
                      _c("span", [_vm._v("Upload file")]),
                    ]),
                  ]
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "content",
            fn: function () {
              return [
                _c("sun-filter-layout", {
                  attrs: {
                    id: "filter-box",
                    "filters-added": _vm.filterFind,
                    "filters-available": _vm.availableFilters,
                    "has-error": _vm.anyError,
                    "show-reset": "",
                  },
                  on: {
                    change: _vm.filtersSetFiltersFind,
                    close: _vm.filtersResetErrors,
                    remove: _vm.filtersResetErrors,
                    reset: _vm.resetFilters,
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "quickFilters",
                      fn: function () {
                        return [
                          _c(
                            "div",
                            { staticClass: "flex flex-wrap lg:flex-no-wrap" },
                            [
                              _c(
                                "div",
                                { staticClass: "m-1" },
                                [
                                  _c("sun-search-input", {
                                    attrs: {
                                      value: _vm.filterQuick["platform.name"],
                                      "class-input": "text-sm",
                                    },
                                    on: {
                                      search: function ($event) {
                                        return _vm.onQuickFiltersSearch(
                                          $event,
                                          "platform.name"
                                        )
                                      },
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "m-1" },
                                [
                                  _c("sun-date-picker", {
                                    key: _vm.datepickerKey,
                                    staticClass: "text-sm",
                                    attrs: {
                                      id: "filter-date",
                                      name: "filterDates",
                                      value: _vm.quickFiltersDate,
                                      separator: "to",
                                      "cancel-error": "",
                                      "show-date-list": "",
                                      required: "",
                                      "single-date-picker": false,
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.onQuickFiltersDate(
                                          $event.value
                                        )
                                      },
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                }),
                _c(
                  "div",
                  {
                    staticClass:
                      "flex flex-col items-end justify-end w-full p-2 rounded-t-md bg-white lg:flex-row lg:items-center",
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "h-10 mb-2 breakdown lg:mb-0 flex items-center",
                      },
                      [
                        _c("breakdown", {
                          attrs: {
                            label: "Select Breakdowns",
                            items: _vm.breakdownOptions,
                            disable: _vm.isLoading,
                          },
                          on: {
                            change: _vm.changeBreakdownSelected,
                            reset: _vm.resetBreakdown,
                          },
                          model: {
                            value: _vm.breakdown,
                            callback: function ($$v) {
                              _vm.breakdown = $$v
                            },
                            expression: "breakdown",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
                _c("sun-data-table", {
                  attrs: {
                    content: _vm.items,
                    detailed: _vm.detailed,
                    headers: _vm.headers,
                    hoverable: "",
                    loading: _vm.isLoading,
                  },
                  on: { expandDetail: _vm.handlerDetail },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: `col.platformName`,
                        fn: function ({ item, columnClass }) {
                          return [
                            _c(
                              "sun-data-table-cell",
                              {
                                class: columnClass,
                                attrs: { "data-testid": "platform-name-col" },
                              },
                              [_vm._v(" " + _vm._s(item.platform.name) + " ")]
                            ),
                          ]
                        },
                      },
                      {
                        key: `col.amount`,
                        fn: function ({ item, columnClass }) {
                          return [
                            _c(
                              "sun-data-table-cell",
                              {
                                class: columnClass,
                                attrs: { "data-testid": "amount-col" },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("currencyFilter")(
                                      item.amount,
                                      item.currency,
                                      _vm.localeForCurrency
                                    )
                                  )
                                ),
                              ]
                            ),
                          ]
                        },
                      },
                      {
                        key: "detailed",
                        fn: function ({ item }) {
                          return [
                            _vm._l(item.children, function (detail, indexRow) {
                              return _c(
                                "sun-data-table-row",
                                { key: indexRow + "_" + item.id },
                                [
                                  _c("sun-data-table-cell", {
                                    attrs: { "detail-cell": "" },
                                  }),
                                  _c("sun-data-table-cell", [
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "text-gray-700 text-xs inline-flex",
                                      },
                                      [
                                        _vm.selectedBreakdown === "day"
                                          ? _c("date-tooltip", {
                                              attrs: { date: detail.date },
                                            })
                                          : [_vm._v(_vm._s(detail.date))],
                                      ],
                                      2
                                    ),
                                  ]),
                                  _c(
                                    "sun-data-table-cell",
                                    { staticClass: "text-right" },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass: "text-gray-700 text-xs",
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm._f("currencyFilter")(
                                                  detail.amount,
                                                  detail.currency,
                                                  _vm.localeForCurrency
                                                )
                                              )
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            }),
                            _vm.canGetMoreItems(item) ||
                            _vm.detailPagination.loading[item.id]
                              ? _c("asterix-table-row-load-more", {
                                  attrs: {
                                    loading:
                                      _vm.detailPagination.loading[item.id],
                                    cols: _vm.headers.length,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleNextDetail(item)
                                    },
                                  },
                                })
                              : _vm._e(),
                          ]
                        },
                      },
                      {
                        key: "footer",
                        fn: function () {
                          return [
                            _c(
                              "sun-data-table-cell",
                              { staticClass: "w-full border-t-2" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "font-bold uppercase pl-2",
                                    attrs: { "data-testid": "name-total-cell" },
                                  },
                                  [_vm._v("Total")]
                                ),
                              ]
                            ),
                            _c(
                              "sun-data-table-cell",
                              { staticClass: "border-t-2" },
                              [
                                _c(
                                  "sun-popover",
                                  {
                                    staticClass: "flex-none",
                                    attrs: { "text-info": "Subtotal" },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "content",
                                        fn: function () {
                                          return [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "block mb-1 text-right",
                                              },
                                              _vm._l(
                                                _vm.totalAmount.subtotal,
                                                function (subtotal, index) {
                                                  return _c(
                                                    "p",
                                                    {
                                                      key: `subtotal_${index}`,
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm._f(
                                                              "currencyFilter"
                                                            )(
                                                              subtotal[1],
                                                              subtotal[0],
                                                              _vm.localeForCurrency
                                                            )
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  )
                                                }
                                              ),
                                              0
                                            ),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ]),
                                  },
                                  [
                                    _c("div", { staticClass: "font-bold" }, [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm._f("currencyFilter")(
                                              _vm.totalAmount.total.amount,
                                              _vm.totalAmount.total.currency,
                                              _vm.localeForCurrency
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                        proxy: true,
                      },
                      {
                        key: "empty",
                        fn: function () {
                          return [
                            _c("asterix-no-data", { staticClass: "bg-white" }),
                          ]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    true
                  ),
                }),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _vm.isNewModalOpen
        ? _c("cost-data-load-modal", {
            attrs: { "data-testid": "upload-modal" },
            on: {
              cancel: function ($event) {
                _vm.isNewModalOpen = false
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }