import { getCostList } from './getCostList';
import QueryParamsBuilder from '@/model/shared/QueryParamsBuilder';

/**
 *
 * @param {QueryParamsBuilder} params
 * @return {Promise<Object>}
 */
export function getCostListBreakdown(params = new QueryParamsBuilder()) {
  return getCostList(params, { preventCancel: true });
}
