import { api } from '.';
import { PLATFORM_RESOURCE, COST_RESOURCE } from '@/services/keys';

/**
 * @param platform
 * @param {File} costFile CSV file
 * @returns {Promise.<object>}
 */
export async function uploadCostFile(platform, costFile) {
  const partialUrl = api.createUrl({
    [PLATFORM_RESOURCE]: platform.id,
    [COST_RESOURCE]: null,
  });
  const bodyData = new FormData();
  bodyData.set('cost_file', costFile);

  const { data } = await api.create(partialUrl, bodyData);
  return data;
}
