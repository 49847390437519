<template>
  <div>
    <asterix-section :status="sectionStatus">
      <template #header-right>
        <sun-button
          variant="pill"
          color="gray"
          class="px-3 custom-p-1 text-xs bg-gray-700 hover:bg-gray-800 h-6 self-end"
          @click="openModal"
        >
          <div class="flex items-center">
            <span class="mr-2"><upload-svg class="w-4" /></span>
            <span>Upload file</span>
          </div>
        </sun-button>
      </template>

      <template #content>
        <sun-filter-layout
          id="filter-box"
          :filters-added="filterFind"
          :filters-available="availableFilters"
          :has-error="anyError"
          show-reset
          @change="filtersSetFiltersFind"
          @close="filtersResetErrors"
          @remove="filtersResetErrors"
          @reset="resetFilters"
        >
          <template #quickFilters>
            <div class="flex flex-wrap lg:flex-no-wrap">
              <div class="m-1">
                <sun-search-input
                  :value="filterQuick['platform.name']"
                  class-input="text-sm"
                  @search="onQuickFiltersSearch($event, 'platform.name')"
                />
              </div>
              <div class="m-1">
                <sun-date-picker
                  id="filter-date"
                  :key="datepickerKey"
                  name="filterDates"
                  class="text-sm"
                  :value="quickFiltersDate"
                  separator="to"
                  cancel-error
                  show-date-list
                  required
                  :single-date-picker="false"
                  @change="onQuickFiltersDate($event.value)"
                />
              </div>
            </div>
          </template>
        </sun-filter-layout>

        <div class="flex flex-col items-end justify-end w-full p-2 rounded-t-md bg-white lg:flex-row lg:items-center">
          <div class="h-10 mb-2 breakdown lg:mb-0 flex items-center">
            <breakdown
              v-model="breakdown"
              label="Select Breakdowns"
              :items="breakdownOptions"
              :disable="isLoading"
              @change="changeBreakdownSelected"
              @reset="resetBreakdown"
            />
          </div>
        </div>

        <sun-data-table
          :content="items"
          :detailed="detailed"
          :headers="headers"
          hoverable
          :loading="isLoading"
          @expandDetail="handlerDetail"
        >
          <template #[`col.platformName`]="{ item, columnClass }">
            <sun-data-table-cell data-testid="platform-name-col" :class="columnClass">
              {{ item.platform.name }}
            </sun-data-table-cell>
          </template>

          <template #[`col.amount`]="{ item, columnClass }">
            <sun-data-table-cell data-testid="amount-col" :class="columnClass">{{
              item.amount | currencyFilter(item.currency, localeForCurrency)
            }}</sun-data-table-cell>
          </template>

          <template #detailed="{ item }">
            <sun-data-table-row v-for="(detail, indexRow) in item.children" :key="indexRow + '_' + item.id">
              <sun-data-table-cell detail-cell />

              <sun-data-table-cell>
                <span class="text-gray-700 text-xs inline-flex">
                  <date-tooltip v-if="selectedBreakdown === 'day'" :date="detail.date" />
                  <template v-else>{{ detail.date }}</template>
                </span>
              </sun-data-table-cell>

              <sun-data-table-cell class="text-right">
                <span class="text-gray-700 text-xs">
                  {{ detail.amount | currencyFilter(detail.currency, localeForCurrency) }}</span
                >
              </sun-data-table-cell>
            </sun-data-table-row>

            <asterix-table-row-load-more
              v-if="canGetMoreItems(item) || detailPagination.loading[item.id]"
              :loading="detailPagination.loading[item.id]"
              :cols="headers.length"
              @click="handleNextDetail(item)"
            />
          </template>

          <template #footer>
            <sun-data-table-cell class="w-full border-t-2">
              <div class="font-bold uppercase pl-2" data-testid="name-total-cell">Total</div>
            </sun-data-table-cell>

            <sun-data-table-cell class="border-t-2">
              <sun-popover class="flex-none" text-info="Subtotal">
                <div class="font-bold">
                  {{ totalAmount.total.amount | currencyFilter(totalAmount.total.currency, localeForCurrency) }}
                </div>
                <template #content>
                  <div class="block mb-1 text-right">
                    <p v-for="(subtotal, index) in totalAmount.subtotal" :key="`subtotal_${index}`">
                      {{ subtotal[1] | currencyFilter(subtotal[0], localeForCurrency) }}
                    </p>
                  </div>
                </template>
              </sun-popover>
            </sun-data-table-cell>
          </template>

          <template #empty>
            <asterix-no-data class="bg-white" />
          </template>
        </sun-data-table>
      </template>
    </asterix-section>

    <cost-data-load-modal v-if="isNewModalOpen" data-testid="upload-modal" @cancel="isNewModalOpen = false" />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { indexMixin } from '@/mixins/index/indexMixin';
import filtersMixin from '@/mixins/filters/filtersMixin';
import { getCostList, getCostListBreakdown } from '@/services/modules/ThirdParty/cost';
import { activeClientMixin } from '@/mixins/common/activeClientMixin';
import AsterixSection from '@/components/templates/AsterixSection';
import CONFIG from './config';
import { stringToDate } from '@/filters/dateFilters';
import QueryParamsBuilder from '@/model/shared/QueryParamsBuilder';
import { CREATE_TOAST } from '@/store/modules/toast/keys';
import { Toast } from '@/model/shared/Toast';
import Breakdown from '@/components/molecules/modules/Analytics/Breakdown/Breakdown';
import currencyFilter from '@/filters/currency';

export default {
  name: 'CostList',
  components: {
    Breakdown,
    AsterixSection,
    AsterixTableRowLoadMore: () => import('@/components/molecules/shared/AsterixTableRowLoadMore'),
    AsterixNoData: () => import('@/components/organisms/shared/AsterixNoData'),
    CostDataLoadModal: () => import('@/components/organisms/modules/thirdParty/cost/CostDataLoadModal'),
    DateTooltip: () => import('@/components/atoms/DateTooltip/DateTooltip'),
    UploadSvg: () => import('@/components/icons/UploadSvg'),
  },
  filters: {
    currencyFilter: (value, currency, locale) => {
      if (currency === '~') {
        const amount = typeof value === 'string' ? parseFloat(value) : value;
        const amountFormatted =
          amount?.toLocaleString(locale, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }) || '';

        return `${amountFormatted} ${currency}`;
      }
      return currencyFilter(value, currency, locale);
    },
  },
  mixins: [
    activeClientMixin,
    indexMixin,
    filtersMixin({
      filters: [],
      filterQuick: {
        'platform.name': undefined,
        range_start: undefined,
        range_end: undefined,
      },
    }),
  ],
  data: () => ({
    isLoading: true,
    headers: CONFIG.headers,
    items: [],
    totalPages: 0,
    breakdown: {},
    breakdownOptions: CONFIG.breakdownOptions,
    isNewModalOpen: false,
    cacheDetails: new Map(),
    defaultDate: CONFIG.defaultDate,
    datepickerKey: 0,
    detailPagination: {
      currentPage: new Map(),
      maxPage: new Map(),
      loading: {},
      itemsPerPage: 10,
    },
    localeForCurrency: 'de-DE', // Hay un problema con ToLocaleString y el locale 'es-ES' para los miles 1.000 lo formatea a 1000
  }),
  computed: {
    selectedBreakdown() {
      return CONFIG.breakdownOptions.items[this.breakdown.index]?.value;
    },
    detailed() {
      return !!this.selectedBreakdown;
    },
    totalAmount() {
      const subtotal = new Map();
      let total = 0;
      let lastCurrency = '~';

      this.items.forEach(({ currency = '', amount = 0 }) => {
        total += amount;
        lastCurrency = currency;
        const lastCurrencyAccount = subtotal.get(currency) || 0;
        subtotal.set(currency, lastCurrencyAccount + amount);
      });

      return {
        subtotal,
        total: {
          amount: total,
          currency: subtotal.size > 1 ? '~' : lastCurrency,
        },
      };
    },
    quickFiltersDate() {
      const rangeStart = this.filterQuick.range_start;
      const rangeEnd = this.filterQuick.range_end;

      const startDate = rangeStart ? stringToDate(rangeStart) : this.defaultDate.startDate;
      const endDate = rangeEnd ? stringToDate(rangeEnd) : this.defaultDate.endDate;
      startDate.setHours(0, 0, 0, 0);
      endDate.setHours(23, 59, 59, 0);

      return { startDate, endDate };
    },
  },
  created() {
    this.filterDefault = [{ name: 'client.id', value: this.activeClient?.id }];
  },
  async mounted() {
    // load filters before api request
    await this.filtersLoadAllfiltersOnMounted();
    this.setDefaultDateFilter();

    const breakdownIndex =
      CONFIG.breakdownOptions.items.findIndex(({ value }) => value === this.queryParams.breakdown) || 0;
    this.breakdown = { index: breakdownIndex };

    await this.getTableItems();
  },
  methods: {
    ...mapActions([CREATE_TOAST]),
    async getTableItems() {
      this.isLoading = true;
      this.cacheDetails.clear();
      /** @type{QueryParamsBuilder} */
      const auxParams = this.setParamsToTable();
      auxParams.itemsPerPage = 100;
      const { data, isCancel } = await this.getItemsFromAPI(getCostList, auxParams);
      this.items = data;
      if (!isCancel) {
        this.isLoading = false;
      }
    },

    async updateFilters(filters = []) {
      await this.resetPageOnQueryParams();

      this.filterFind = filters;
      await this.filtersUpdateFiltersOnStoreAndURL();
    },
    async changeFilters(filters) {
      await this.updateFilters(filters);
      await this.getTableItems();
    },
    setFilterQuick({ startDate, endDate }) {
      startDate.setHours(0, 0, 0, 0);
      endDate.setHours(23, 59, 59, 0);
      this.filterQuick.range_start = startDate.toISOString();
      this.filterQuick.range_end = endDate.toISOString();

      // TODO Is this necessary?
      this.datepickerKey++;
    },
    setDefaultDateFilter() {
      this.setFilterQuick(this.quickFiltersDate);
    },
    async onQuickFiltersDate(value) {
      this.setFilterQuick(value || this.defaultDate);
      await this.changeFilters(this.filterFind);
    },

    openModal() {
      this.isNewModalOpen = true;
    },
    async resetBreakdown() {
      this.breakdown = {};
      this.queryParams.breakdown = undefined;
      this.removeQueryParam('breakdown');
      await this.queryParamsRouterReplace();
    },
    async changeBreakdownSelected() {
      this.queryParams.breakdown = this.selectedBreakdown;
      this.addQueryParams({ breakdown: this.selectedBreakdown });

      this.closeDetails();

      this.detailPagination.currentPage.clear();
      this.detailPagination.maxPage.clear();
      this.detailPagination.loading = {};

      await this.queryParamsRouterReplace();
    },
    closeDetails() {
      this.cacheDetails.forEach(index => {
        this.$set(this.items[index], 'children', undefined);
        this.$set(this.items[index], 'openChildren', false);
      });
      this.cacheDetails.clear();
    },

    canGetMoreItems(item) {
      const currentItemPage = this.detailPagination.currentPage.get(item.id) || 1;
      const itemMaxPage = this.detailPagination.maxPage.get(item.id) || 1;
      return currentItemPage < itemMaxPage;
    },
    async handleNextDetail(item) {
      const currentItemPage = this.detailPagination.currentPage.get(item.id) || 1;
      const itemMaxPage = this.detailPagination.maxPage.get(item.id) || 1;
      if (currentItemPage < itemMaxPage) {
        this.detailPagination.currentPage.set(item.id, currentItemPage + 1);
        const index = this.items.findIndex(row => row.id === item.id);

        if (index > -1) {
          await this.handlerDetail({ index, item, expand: true });
        }
      }
    },
    async handlerDetail({ index, item, expand }) {
      const currentItemPage = this.detailPagination.currentPage.get(item.id) || 1;

      if ((expand && !this.cacheDetails.has(item.id)) || currentItemPage > 1) {
        this.$set(this.detailPagination.loading, item.id, true);
        const pageParams = new QueryParamsBuilder(currentItemPage, this.detailPagination.itemsPerPage);
        const auxParams = this.setParamsToTable(pageParams);

        auxParams.setBreakdown(this.selectedBreakdown);
        auxParams.addFilter('platform.id', item.platform.id);
        auxParams.addFilter('cost.currency', item.currency);

        try {
          const { data, meta } = await getCostListBreakdown(auxParams);
          // Format date: 'Month Year'
          if (this.selectedBreakdown === 'month') {
            data.forEach(el => {
              const date = new Date(el.year, el.month - 1, el.day);
              const month = date.toLocaleString('en-US', { month: 'long' });
              el.date = `${month} ${el.year}`;
            });
          }

          this.detailPagination.maxPage.set(item.id, meta.totalPages);
          this.cacheDetails.set(item.id, index);

          this.$set(item, 'children', item.children?.concat(data) || data);
        } catch (error) {
          this[CREATE_TOAST](
            Toast.error(`Oops! We couldn't get the data`, 'Please try again later or review the filters.')
          );
        } finally {
          this.$set(this.detailPagination.loading, item.id, false);
        }
      }
    },
  },
};
</script>

<style scoped>
.breakdown {
  min-width: 200px;
}

table td {
  max-width: 100%;
}
</style>
